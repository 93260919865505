import React from "react";
import CeWrapper from "../../global/CeWrapper";
import ContentLibs from "../../../libs/ContentLibs";
import ContainerRow from "../../global/ContainerRow";
import ContainerColumn from "../../global/ContainerColumn";
import Components from "../../index";
import CeDataGetter from "../../../libs/CeDataGetter";

const ce = ({ ceData }) => {
  const layoutMappings = {
    100: "header-left-dark",
    101: "bg-color-gray",
  };

  let contentElementsColumn1 = [];

  if (
    ceData.contentParts.column_0?.contents &&
    Array.isArray(ceData.contentParts.column_0.contents)
  ) {
    contentElementsColumn1 = ContentLibs.unifyContentArray(
      ceData.contentParts.column_0.contents
    );
  }

  const enableDefaultGridWrap = ceData.properties?.frameClass === "fluid";

  return (
    <CeWrapper
      ceData={ceData}
      additionalClassName={`ce--container ${
        parseInt(ceData.properties?.layout) === 100 &&
        !CeDataGetter.getCeMainHeadlineTitle(ceData)
          ? "ce--layout-header-left-dark--without-headline"
          : ""
      }`}
      enableDefaultGridWrap={enableDefaultGridWrap}
      layoutMapping={layoutMappings}
    >
      <div className="ce__container-content">
        <ContainerRow>
          <ContainerColumn className={"large-12"}>
            {contentElementsColumn1 &&
              contentElementsColumn1.map((contentItem, i) =>
                Components(contentItem, i)
              )}
          </ContainerColumn>
        </ContainerRow>
      </div>
    </CeWrapper>
  );
};

export default ce;
